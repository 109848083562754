import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import useFilterCounter from '@/components/Filters/useFiltersCount'
import { debounce } from 'lodash'

export default function useList(props) {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('center/getAll')
  store.dispatch('sector/getAll')
  store.dispatch('tenant/getAll')
  store.dispatch('cm_reseller/getAll')
  store.dispatch('camera_type/getAll')
  store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })

  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)
  const resellerOptions = computed(() => store.getters['cm_reseller/getSelectedItems'])
  const resellerFilter = ref(null)
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)
  const cameraTypeOptions = computed(() => store.getters['camera_type/getSelectedItems'])
  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])
  const onlineFilter = ref(null)
  const rtspAvailableFilter = ref(null)
  const enabledSmartDetectionFilter = ref(null)
  const enabledPersonSmartDetectionFilter = ref(null)
  const startDatetimeFilter = ref(null)
  const endDatetimeFilter = ref(null)

  if (router.currentRoute.params.id_reseller)
    resellerFilter.value = [parseInt(router.currentRoute.params.id_reseller)]

  let tableColumns = [
    { label: 'camera.title', key: 'name', sortable: true },
    { label: 'reseller.title', key: 'reseller.contactEmail', sortable: true },
    {
      label: 'tenants.title', key: 'tenants', sortable: false,
      formatter: (value, key, item) => {
        if (item.units) return [...new Set(item.units.map(item => { return item.router?.tenant?.name }))].join(', ')
        return ""
      }
    },
    {
      label: 'unit.title', key: 'units', sortable: false,
      formatter: (value, key, item) => {
        if (value) return value.map(item => { return item.description }).join(', ')
        return ""
      }
    },
    {
      label: 'centers.title', key: 'center.name', sortable: true,
    },
    {
      label: 'sectors.title', key: 'sector.name', sortable: true,
    },
    { label: 'camera_proxy.last_connection', key: 'camera.last_connection', sortable: true },
    { label: 'camera_proxy.id', key: 'camera.id', sortable: true },
    { label: 'camera.camera_id', key: 'cameraId', sortable: true },
    { label: 'camera.mac_address', key: 'ethMacAddress', sortable: true, tdClass: 'mac-td' },
    {
      label: 'camera.type', key: 'camera_type.type', sortable: true,
    },
    {
      label: 'device_tag.title', key: 'device_tag.name', sortable: true,
    },
    { label: 'camera.previous_time_of_prealarma', key: 'delayed_record_minutes', sortable: true },
    { label: 'camera.later_time_of_prealarma', key: 'forward_record_minutes', sortable: true },
    { label: 'camera.people_detection', key: 'check_events', sortable: true },
    { label: 'camera.people_detection_start', key: 'start_time_event', sortable: true },
    { label: 'camera.end_of_person_detection', key: 'end_time_event', sortable: true },
    { label: 'Enabled Smart Detection CM', key: 'is_smart_detection_enabled', sortable: true },
    { label: 'Enabled Person Smart Detection CM', key: 'is_smart_detection_person_enabled', sortable: true },
    { label: 'camera.serial_number', key: 'serial_number', sortable: true },
    { label: 'camera_proxy.rtsp_available', key: 'camera.rtsp_available', sortable: true },
    {
      label: 'date.created_at', key: 'created', sortable: true, formatter: 'formatDateAssigned',
    },
    {
      label: 'date.updated_at', key: 'updated', sortable: true, formatter: 'formatDateAssigned',
    },
  ];

  if (props.showFilters) {
    tableColumns = [{ label: 'actions.name', key: 'actions' }].concat(tableColumns);
  }

  if (ability.can('read_user_and_password', 'CameraManager')) {
    tableColumns.splice(22, 0, { label: 'camera.password', key: 'password', sortable: false })
    tableColumns.splice(22, 0, { label: 'camera.user', key: 'user', sortable: true })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('cm_camera/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_reseller: resellerFilter.value,
      id_center: centerFilter.value,
      id_sector: sectorFilter.value,
      id_tenant: tenantFilter.value,
      online: onlineFilter.value,
      rtsp_available: rtspAvailableFilter.value,
      is_smart_detection_enabled: enabledSmartDetectionFilter.value,
      is_smart_detection_person_enabled: enabledPersonSmartDetectionFilter.value,
      from: startDatetimeFilter.value,
      to: endDatetimeFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([
    currentPage,
    perPage,
    ],
  () => {
    refetchData()
  })

  watch([
    searchQuery
  ],
  debounce(() =>
    refetchData()
  , 500));



  const applyChartFilters = (filters) => {
    if (filters) {
      centerFilter.value = filters['id_center'] || null;
      sectorFilter.value = filters['id_sector'] || null;
      tenantFilter.value = filters['id_tenant'] || null;
      onlineFilter.value = filters['online'] || null;

      if (filters['from'] && filters['to']) {
        startDatetimeFilter.value = filters['from'];
        endDatetimeFilter.value = filters['to'];
      }
    }
  }

  // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      center: centerFilter.value,
      tenant: tenantFilter.value,
      reseller: resellerFilter.value,
      sector: sectorFilter.value,
      online: onlineFilter.value,
      rtsp_available: rtspAvailableFilter.value,
      is_smart_detection_enabled: enabledSmartDetectionFilter.value,
      is_smart_detection_person_enabled: enabledPersonSmartDetectionFilter.value,
      from: startDatetimeFilter.value,
      to: endDatetimeFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    centerFilter.value = null;
    tenantFilter.value = null;
    resellerFilter.value = null;
    sectorFilter.value = null;
    onlineFilter.value = null;
    rtspAvailableFilter.value = null;
    enabledSmartDetectionFilter.value = null;
    enabledPersonSmartDetectionFilter.value = null;
    startDatetimeFilter.value = null;
    endDatetimeFilter.value = null;
    buildFilters();
  }
  // ============================================

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    centerOptions,
    centerFilter,
    sectorOptions,
    sectorFilter,
    tenantOptions,
    tenantFilter,

    resellerOptions,
    resellerFilter,
    cameraTypeOptions,
    unitOptions,
    onlineFilter,
    rtspAvailableFilter,
    enabledSmartDetectionFilter,
    enabledPersonSmartDetectionFilter,

    refetchData,
    applyChartFilters,

    // filters counter
    filtersCount,
    buildFilters,
    clearSearchFilters,
  }
}
