<template>
  <v-select
    :options="paginated"
    :filterable="false"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    @search="onSearch"
    :value="selected"
    :multiple="multiple"
    :clereable="clereable"
    :closeOnSelect="!multiple"
    ref="selectable"
    @option:deselected="
      (deselectedOption) => $emit('option:deselected', deselectedOption)
    "
    @option:selected="(selectedOption) => $emit('option:selected', selectedOption)"
    @input="
      (val) => {
        $emit('update:selected', val);
        $emit('change', val);
      }
    "
  >
    <template #search="{ attributes, events }">
      <input style="width: 100%" class="vs__search" v-bind="attributes" v-on="events" />
    </template>
    <li slot="list-footer" class="pagination">
      <b-button
        variant="outline-secondary"
        :disabled="!hasPrevPage"
        @click="offset -= limit"
      >
        Anterior
      </b-button>
      <b-button
        variant="outline-secondary"
        :disabled="!hasNextPage"
        @click="offset += limit"
      >
        Siguiente
      </b-button>
    </li>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
export default {
  components: {
    vSelect,
    BButton,
  },
  model: {
    prop: "selected",
    event: "update:selected",
  },
  props: {
    items: { type: Array, required: true },
    selected: { type: [Number, Array, Object], required: false },
    multiple: { type: Boolean, required: false, default: false },
    clereable: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    search: "",
    offset: 0,
    limit: 10,
  }),
  computed: {
    filtered() {
      const eFiltered = this.items.filter((item) =>
        item.label.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      );
      return eFiltered;
    },
    paginated() {
      let items = [];
      if (this.selected) {
        // let item = this.items.filter((item) =>
        //   this.selected instanceof Array
        //     ? this.selected.includes(item)
        //     : this.selected.value == item.value
        // );
        items = this.filtered.slice(this.offset, this.limit + this.offset);

        // if (!item) items.concat(this.selected);
      } else items = this.filtered.slice(this.offset, this.limit + this.offset);

      return items;
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit;
      return Boolean(this.filtered.slice(nextOffset, this.limit + nextOffset).length);
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit;
      return Boolean(this.filtered.slice(prevOffset, this.limit + prevOffset).length);
    },
  },
  updated() {
    setTimeout(() => {
      this.checkHideElements();
    }, 500);
  },
  methods: {
    onSearch(query) {
      this.search = query;
      this.offset = 0;
    },
    checkHideElements() {
      let i = 0;
      this.$refs.selectable.$el.querySelectorAll(".vs__selected").forEach((element) => {
        if (i > this.$refs.selectable.$el.querySelectorAll(".vs__selected").length - 21)
          element.classList.remove("d-none");
        else element.classList.add("d-none");

        i++;
      });
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}
.pagination button {
  flex-grow: 1;
}
.pagination button:hover {
  cursor: pointer;
}
</style>
