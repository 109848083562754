<template>
  <div>
    <b-row align-v="center" class="d-flex align-items-center">
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $tc('tenants.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tenantFilter"
            :options="tenantOptions"
            class="w-100"
            :reduce="(val) => val.value"
            multiple
            @input="(val) => $emit('update:tenantFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
      <b-col
        cols="6"
        class="mb-2"
      >
      <label class="text-capitalize">{{ $tc('reseller.title', 2) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="resellerFilter"
          :options="resellerOptions"
          class="w-100"
          :reduce="val => val.value"
          multiple
          @input="(val) => $emit('update:resellerFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        class="mb-2"
      >
      <label class="text-capitalize">{{$tc('centers.title', 2)}}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="centerFilter"
          :options="centerOptions"
          class="w-100"
          :reduce="val => val.value"
          multiple
          @input="(val) => $emit('update:centerFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
      <b-col
        cols="6"
        class="mb-2"
      >
      <label class="text-capitalize">{{ $tc('sectors.title', 2) }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="sectorFilter"
          :options="sectorOptions"
          class="w-100"
          :reduce="val => val.value"
          multiple
          @input="(val) => $emit('update:sectorFilter', val)"
          append-to-body
          :calculate-position="withPopper"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-2 text-capitalize">
        <label class="text-capitalize">{{ $t('camera.online') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="onlineFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
            @input="(val) => $emit('update:onlineFilter', val)"
          />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{ $t('camera_proxy.rtsp_available') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="rtspAvailableFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
            @input="(val) => $emit('update:rtspAvailableFilter', val)"
          />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" class="mb-0">
        <label class="text-capitalize">{{$t('camera.enable_smart_detection')}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="enabledSmartDetectionFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
            @input="(val) => $emit('update:enabledSmartDetectionFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
      <b-col cols="6" class="mb-2">
        <label class="text-capitalize">{{$t('camera.enable_person_smart_detection')}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="enabledPersonSmartDetectionFilter"
            class="w-100"
            :reduce="val => val.value"
            :options="[{label: 'enabled', value: true}, {label: 'disabled', value: false}]"
            @input="(val) => $emit('update:enabledPersonSmartDetectionFilter', val)"
            append-to-body
            :calculate-position="withPopper"
          />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import popperHelper from "@/libs/popper-helper";


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    tenantOptions: {
      type: Array,
      default: null,
    },
    tenantFilter: {
      type: Array,
      default: null,
    },
    resellerOptions: {
      type: Array,
      default: null,
    },
    resellerFilter: {
      type: Array,
      default: null,
    },
    buttonUpdate: {
      type: Boolean,
      default: false,
    },
    onlineFilter: {
      type: Boolean,
      default: false,
    },
    rtspAvailableFilter: {
      type: Boolean,
      default: false,
    },
    enabledSmartDetectionFilter: {
      type: Boolean,
      default: false,
    },
    enabledPersonSmartDetectionFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colsSize() {
      return this.userData.is_master_admin ? '6' : '6';
    }
  },
  setup() {
    return {
      withPopper: popperHelper.withPopper,
    }
  }

}
</script>
